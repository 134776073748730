import { Fragment, useEffect, useState } from "react";

import Breadcrumbs from "@layout/breadcrumb";
import BadgeApproval from "@components/badgeApproval";
import AlertApproval from "@components/alertApproval";
import { useParams } from "react-router-dom";
import {
  approveRejectRevisiPromoNew,
  getDetailPromoNew,
} from "@action/promoAction";
import { useDispatch } from "react-redux";
import BadgeStatus from "@components/badgeStatus";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import toCurrency from "@components/helpers/toCurrency";

const DetailPromotion = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [detailData, setDetailData] = useState({
    nama: "",
    kode: "",
    start_at: "",
    end_at: "",
    is_active: 0,
    tipe_promo: {
      title: "",
    },
    approval_status: {
      approval: 0,
    },
    approval_action: false,
    banner: "",
    deskripsi: "",
    ketentuan: [],
    benefit: [],
    super_tier: [],
  });

  const [modalData, setModalData] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedSales, setSelectedSales] = useState([]);
  const [selectedWlArea, setSelectedWlArea] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const [selectedManufactur, setSelectedManufactur] = useState([]);
  const [selectedKategori, setSelectedKategori] = useState([]);
  const [selectedSKU, setSelectedSKU] = useState([]);

  const [selectedKetentuan, setSelectedKetentuan] = useState([]);

  function handleAlert() {
    setModalData({
      isOpen: false,
      isNote: false,
      title: "",
      subTitle: "",
    });
  }

  const onApproveReject = async (status) => {
    const tab = detailData.approval_action ? "#tab=1" : "";
    dispatch(approveRejectRevisiPromoNew(id, status)).then((res) => {
      console.log(`onotrak res promo ${status} :`, res);
      if (res?.status !== 200) return false;
      history.push(`/dashboard/promotion${tab}`);
    });
  };

  useEffect(() => {
    dispatch(getDetailPromoNew(id)).then((res) => {
      console.log("onotrak res detail promo", res);
      if (res?.status !== 200) return false;
      if (!res?.data?.data) return false;
      setDetailData(res.data.data);
      transformData(res.data.data.promo_condition);
    });
  }, [id]);

  const transformData = (data) => {
    if (!data?.length) return;
    //Customers
    const customersData = data.find(
      (item) => item.type === "customers" && item.level === 1
    );
    console.log("[onotrak LOG]: customers", customersData);

    const customers = customersData.promo_value.find(
      (item) => item.type === "customers"
    );
    const sales = customersData.promo_value.find(
      (item) => item.type === "sales"
    );
    const wl_area = customersData.promo_value.find(
      (item) => item.type === "wl_area"
    );
    const jenis_customer = customersData.promo_value.find(
      (item) => item.type === "jenis_customer"
    );
    setSelectedCustomer(customers?.promo_value_detail || []);
    setSelectedSales(sales?.promo_value_detail || []);
    setSelectedWlArea(wl_area?.promo_value_detail || []);
    setSelectedUser(jenis_customer?.promo_value_detail || []);

    //Products
    const productsData = data.find(
      (item) => item.type === "products" && item.level === 1
    );
    console.log("[onotrak LOG]: products", productsData);

    const manufactur = productsData.promo_value.find(
      (item) => item.type === "manufactur"
    );
    const kategori = productsData.promo_value.find(
      (item) => item.type === "kategori"
    );
    const SKU = productsData.promo_value.find((item) => item.type === "sku");
    setSelectedManufactur(manufactur?.promo_value_detail || []);
    setSelectedKategori(kategori?.promo_value_detail || []);
    setSelectedSKU(SKU?.promo_value_detail || []);

    const ketentuan = transformSelectedDataSKU(SKU?.promo_value_detail);
    setSelectedKetentuan(transformDataKetentuan(ketentuan));
    console.log("onotrak ketentuan", transformDataKetentuan(ketentuan));
  };
  const transformSelectedDataSKU = (data) => {
    if (!data?.length) return [];
    return data.map((item) => {
      if (item?.detail) {
        return {
          ...item.detail,
          name: item?.detail?.name || "-",
          id: item?.detail?.id || 0,
          data: item?.promo_tier?.length
            ? item.promo_tier.map((tier) => {
                return {
                  is_active: tier.is_active,
                  type: tier.type,
                  type_condition: tier.type_condition,
                  value: tier.value,
                };
              })
            : [],
        };
      }
      return { name: "-", id: 0 };
    });
  };

  const transformDataKetentuan = (selectedSKU) => {
    const data = [];

    // Iterasi melalui setiap produk di selectedSKU
    selectedSKU.forEach((product) => {
      // Iterasi melalui setiap data dalam produk tersebut
      product.data.forEach((item, index) => {
        // Memastikan bahwa item aktif dan type_condition adalah 'tiering'
        if (item.is_active === 1 && item.type_condition === "tiering") {
          // Tier berdasarkan urutan data (index + 1)
          const tierName = `Tier ${index + 1}`;

          // Cek apakah tier sudah ada di data
          let tier = data.find((tier) => tier.tierName === tierName);

          // Jika tier belum ada, buat object baru
          if (!tier) {
            tier = {
              tierName,
              data: [],
            };
            data.push(tier);
          }

          // Tambahkan produk ke dalam tier tersebut
          tier.data.push({
            id: product.id,
            name: product.name,
            value: item.value,
            type: item.type,
          });
        }
      });
    });
    return data;
  };

  const isCustomer = selectedCustomer?.length;
  const isSales = selectedSales?.length;
  const isWLarea = selectedWlArea?.length;
  const isUser = selectedUser?.length;

  const isManufactur = selectedManufactur?.length;
  const isKategori = selectedKategori?.length;
  const isSKU = selectedSKU?.length;

  return (
    <Fragment>
      <Breadcrumbs
        parent="Promotion"
        title="Detail Promosi"
        urlParent={"/dashboard/promotion"}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="bg-white shadow-sm col-sm-12 py-2 rounded-lg h-100 col-xl-4 mt-1">
            <div className="d-flex flex-row">
              <h5 className="my-auto">{detailData.nama || "-"}</h5>
              <div className="mx-2 my-auto">
                {detailData?.approval_status ? (
                  <BadgeApproval
                    status={detailData?.approval_status?.approval}
                  />
                ) : (
                  <BadgeStatus status={detailData.is_active} />
                )}
              </div>
            </div>
            <div className="d-flex border-bottom pb-4 my-4">
              <img
                className="rounded-lg bg-light w-100"
                src={detailData.banner}
                alt="Banner Promo"
                height="211"
              />
            </div>
            <div className="d-flex flex-row my-3">
              <div className="d-flex flex-column w-50">
                <span className="text-secondary">Kode Promo</span>
                <span className="">{detailData.kode || "-"}</span>
              </div>
              <div className="d-flex flex-column w-50">
                <span className="text-secondary">Kuota Promo</span>
                <span className="">{detailData.kode || "-"}</span>
              </div>
            </div>
            <div className="d-flex flex-row my-3">
              <div className="d-flex flex-column w-50">
                <span className="text-secondary">Tipe Promo</span>
                <span className="">{detailData.tipe_promo.title}</span>
              </div>
              <div className="d-flex flex-column w-50">
                <span className="text-secondary">Periode</span>
                {!detailData.start_at ? (
                  <span className="">-</span>
                ) : (
                  <span className="">
                    {`${moment(detailData.start_at || "").format(
                      "DD/MM/YYYY"
                    )} - ${moment(detailData.end_at || "").format(
                      "DD/MM/YYYY"
                    )}`}
                  </span>
                )}
              </div>
            </div>
            <div className="d-flex flex-column my-3">
              <span className="text-secondary">Deskripsi</span>
              <span className="">{detailData.deskripsi}</span>
            </div>
          </div>

          <div className="col-sm-12 ml-xl-auto col-xl-8 mt-1">
            <div className="row justify-content-between">
              <div className="w-50 px-2 pl-3">
                <div className="bg-white shadow-sm mb-3 px-3 p-3 rounded-lg">
                  <h6 className="mb-3 font-weight-bold">Customer</h6>
                  <span>
                    {!isCustomer ? null : (
                      <span>
                        Customer (
                        {selectedCustomer.map((item, index) => (
                          <span key={item.id}>{`${
                            item?.detail?.name
                            // item?.detail?.shop_name
                          }${index + 1 !== isCustomer ? ", " : ""}`}</span>
                        ))}
                        ){isSales || isWLarea || isUser ? " dan " : ""}
                      </span>
                    )}
                    {!isSales ? null : (
                      <span>
                        Sales (
                        {selectedSales.map((item, index) => (
                          <span key={item.id}>{`${item?.detail?.name}${
                            index + 1 !== isSales ? ", " : ""
                          }`}</span>
                        ))}
                        ) {isWLarea || isUser ? " dan " : ""}
                      </span>
                    )}
                    {!isWLarea ? null : (
                      <span>
                        WL Area (
                        {selectedWlArea.map((item, index) => (
                          <span key={item.id}>{`${item?.detail?.name}${
                            index + 1 !== isWLarea ? ", " : ""
                          }`}</span>
                        ))}
                        ) {isUser ? " dan " : ""}
                      </span>
                    )}
                    {!isUser ? null : (
                      <span>
                        Jenis Customer (
                        {selectedUser.map((item, index) => (
                          <span key={item.id}>{`${item?.detail?.name}${
                            index + 1 !== isUser ? ", " : ""
                          }`}</span>
                        ))}
                        )
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <div className="w-50 px-2 pr-3">
                <div className="bg-white shadow-sm mb-3 px-3 p-3 rounded-lg">
                  <h6 className="mb-3 font-weight-bold">Product</h6>
                  <span>
                    {!isManufactur ? null : (
                      <span>
                        Manufactur (
                        {selectedManufactur.map((item, index) => (
                          <span key={item.id}>{`${item?.detail?.name}${
                            index + 1 !== isManufactur ? ", " : ""
                          }`}</span>
                        ))}
                        ) {isKategori || isSKU ? " dan " : ""}
                      </span>
                    )}
                    {!isKategori ? null : (
                      <span>
                        Kategori (
                        {selectedKategori.map((item, index) => (
                          <span key={item.id}>{`${item?.detail?.name}${
                            index + 1 !== isKategori ? ", " : ""
                          }`}</span>
                        ))}
                        ) {isSKU ? " dan " : ""}
                      </span>
                    )}
                    {!isSKU ? null : (
                      <span>
                        SKU (
                        {selectedSKU.map((item, index) => (
                          <span key={item.id}>{`${item?.detail?.name}${
                            index + 1 !== isSKU ? ", " : ""
                          }`}</span>
                        ))}
                        )
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </div>

            {selectedKetentuan?.length || detailData.super_tier.length ? (
              <div className="bg-white shadow-sm mb-3 px-3 py-2 p-t-15 rounded-lg w-100">
                <span className="font-weight-bold h6">Ketentuan</span>
                {selectedKetentuan.map((tier, tierIdx) => (
                  <div className="bg-light my-2 p-2 rounded-lg text-dark">
                    <div key={tierIdx + 1}>
                      <div className="font-weight-bold mb-2">
                        {tier.tierName || "-"}
                      </div>
                      {tier.data.map((product, idxProduct) => (
                        <div
                          key={idxProduct + 1}
                          className="d-flex flex-row justify-content-between mb-2"
                        >
                          <span>{product.name || "-"}</span>
                          {product?.type === "value" ? (
                            <span>{toCurrency(product.value || "-")}</span>
                          ) : (
                            <span>x{product.value || "-"}</span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                {detailData.super_tier.length ? (
                  <div className="bg-light my-2 p-2 rounded-lg text-dark">
                    <div className="font-weight-bold mb-2">SUPER TIER</div>
                    {detailData.super_tier.map((tier, tierIdx) => (
                      <div key={tierIdx + 1}>
                        <div className="d-flex flex-row justify-content-between mb-2">
                          <span> Tier {tier.tier || "-"}</span>
                          <span>x{tier.qty || "0"}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
                {/* {!supertier.length || !superTierIsActive ? null : (
									<div className="bg-light my-2 p-2 rounded-lg text-dark">
										<div className="font-weight-bold mb-2">
											SUPER TIER
										</div>
										{supertier.map((tier, tierIdx) => (
											<div
												key={tierIdx + 1}
												className="d-flex flex-row justify-content-between mb-2"
											>
												<span>{tier.name}</span>
												<span>x{tier.value}</span>
											</div>
										))}
									</div>
								)} */}
              </div>
            ) : null}

            {detailData?.benefit?.length ? (
              <div className="bg-white shadow-sm mb-3 px-3 py-2 p-t-15 rounded-lg w-100">
                <span className="font-weight-bold h6">Benefit</span>
                {detailData.benefit.map((tier, tierIdx) => (
                  <div className="bg-light my-2 p-2 rounded-lg text-dark">
                    <div key={tierIdx + 1}>
                      <div className="font-weight-bold mb-2">
                        {`${
                          tier.is_super_tier
                            ? "SUPER TIER"
                            : tier.tier_id
                            ? `Tier ${tierIdx + 1}`
                            : ""
                        }`}
                      </div>
                      <span>
                        {formatText(tier.type_benefit) || ""}{" "}
                        {tier.type === "amount" ? "Rp. " : ""}
                        {tier.value || ""}
                        {tier.type === "percentage" ? "%" : ""}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}

            {detailData.approval_action ? (
              <div className="d-flex flex-row justify-content-end my-3">
                <button
                  onClick={() => onApproveReject("reject")}
                  className="bg-white mr-2 px-3 py-2 border-light rounded"
                >
                  Reject
                </button>
                <button
                  onClick={() => onApproveReject("approve")}
                  className="mr-2 px-3 py-2 rounded btn-primary"
                >
                  Approve
                </button>
              </div>
            ) : null}
          </div>
        </div>
        <AlertApproval
          isOpenAlert={modalData.isOpen}
          isNote={modalData.isNote || false}
          modalTitle={modalData.title}
          modalSubTitle={modalData.subTitle}
          handleToggle={handleAlert}
        />
      </div>
    </Fragment>
  );
};

const formatText = (text) => {
  return text
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export default DetailPromotion;
