import { Fragment, useEffect, useState } from "react";
import SwitchAndOr from "./SwitchAndOr";
import PopUpKondisi from "./PopUpKondisi";
import FilterByManufacture from "./filterByManufacture";
import FilterByKategori from "./filterByKategori";
import FilterBySKU from "./filterBySKU";

const FilterByKondisiProduct = ({
  onDeleteFilter,
  item,
  productCondition,
  setProductCondition,
  isAddKondisi,
  setAddKondisi,
}) => {
  const [openModal, setModal] = useState(false);

  //manufactur
  const [isManufaktur, setManufaktur] = useState(false);
  const [manufakturData, setManufakturData] = useState({
    value: "",
    type: "manufactur",
  });
  const [selectedManufactur, setSelectedManufactur] = useState([]);

  // Kategori
  const [isKategori, setKategori] = useState(false);
  const [kategoriData, setKategoriData] = useState({
    value: "",
    type: "kategori",
  });
  const [selectedKategori, setSelectedKategori] = useState([]);

  //SKU
  const [isSKU, setSKU] = useState(false);
  const [skuData, setSkuData] = useState({
    value: "",
    type: "sku",
    group: "kelipatan",
  });
  const [selectedSKU, setSelectedSKU] = useState([]);

  function handleAddFilter(val) {
    switch (val) {
      case "manufactur":
        setManufaktur(true);
        break;
      case "kategori":
        setKategori(true);
        break;
      case "sku":
        setSKU(true);
        break;
      default:
        break;
    }
  }

  const handleChangeItems = (val, data) => {
    setAddKondisi((prevData) =>
      prevData.map((kondisi) => {
        const newData = {
          value: data.value,
          type: data.type,
          items: val.map((item) => item.id.toString()),
        };

        if (kondisi.name === item.name) {
          if (!kondisi?.data || kondisi.data.length === 0) {
            return {
              ...kondisi,
              data: [newData],
            };
          }

          const newConditions = kondisi.data.some(
            (itm) => itm.type === data.type
          )
            ? kondisi.data.map((itm) =>
                itm.type === data.type ? newData : itm
              )
            : [...kondisi.data, newData];

          return {
            ...kondisi,
            data: newConditions,
          };
        }

        return kondisi;
      })
    );
  };

  const filterDataSku = (dataArray) => {
    return dataArray.map((obj) => ({
      relation_id: obj.relation_id || "",
      data: obj?.data?.length ? obj.data : [],
    }));
  };
  const handleChangeSKUItems = () => {
    setAddKondisi((prevData) =>
      prevData.map((kondisi) => {
        const newData = {
          value: skuData.value,
          type: skuData.type,
          items: filterDataSku(selectedSKU),
          // items: selectedSKU.map((item) => item.id.toString()),
        };

        if (kondisi.name === item.name) {
          if (!kondisi?.data || kondisi.data.length === 0) {
            return {
              ...kondisi,
              data: [newData],
            };
          }

          const newConditions = kondisi.data.some(
            (itm) => itm.type === skuData.type
          )
            ? kondisi.data.map((itm) =>
                itm.type === skuData.type ? newData : itm
              )
            : [...kondisi.data, newData];

          return {
            ...kondisi,
            data: newConditions,
          };
        }

        return kondisi;
      })
    );
  };

  const handleChangeValue = (newValue, dataType) => {
    setAddKondisi((prevData) =>
      prevData.map((kondisi) => {
        const newConditions =
          kondisi.data && kondisi.data.length > 0
            ? kondisi.data.map((itm) => {
                if (itm.type === dataType) {
                  return {
                    ...itm,
                    value: newValue,
                  };
                }
                return itm;
              })
            : [];

        return {
          ...kondisi,
          data: newConditions,
        };
      })
    );
  };

  useEffect(() => {
    if (selectedSKU?.length) {
      handleChangeSKUItems();
    }
  }, [selectedSKU]);

  return (
    <Fragment>
      <div className="bg-light p-3 border rounded-lg text-center text-dark mb-2">
        <div className="d-flex flex-row justify-content-between mb-2">
          <h5>Kondisi {item.name}</h5>
          <div className="d-flex">
            <SwitchAndOr
              value={productCondition}
              onChange={(val) => setProductCondition(val)}
            />
            <button
              type="button"
              className="ml-2 btn btn-primary"
              onClick={() => setModal(!openModal)}
            >
              {"+"} Tambah Kondisi
            </button>
            <button className="border-0 bg-none" onClick={onDeleteFilter}>
              <i
                className="fa fa-trash"
                aria-hidden="true"
                style={{ color: "red" }}
              ></i>
            </button>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center my-2 p-3">
          {isManufaktur && (
            <FilterByManufacture
              onToggle={() => {
                setManufakturData({ value: "", type: "manufactur" });
                setSelectedManufactur([]);
                setManufaktur(!isManufaktur);
              }}
              selectedData={selectedManufactur}
              setSelectedData={(val) => {
                setSelectedManufactur(val);
                handleChangeItems(val, manufakturData);
              }}
              filterData={manufakturData}
              setFilterData={(e) => {
                setManufakturData(e);
                handleChangeValue(e.value, manufakturData.type);
              }}
            />
          )}
          {isKategori && (
            <FilterByKategori
              onToggle={() => {
                setKategoriData({ value: "", type: "kategori" });
                setSelectedKategori([]);
                setKategori(!isKategori);
              }}
              selectedData={selectedKategori}
              setSelectedData={(val) => {
                setSelectedKategori(val);
                handleChangeItems(val, kategoriData);
              }}
              filterData={kategoriData}
              setFilterData={(e) => {
                setKategoriData(e);
                handleChangeValue(e.value, kategoriData.type);
              }}
            />
          )}
          {isSKU && (
            <FilterBySKU
              idKP={item.name}
              typeRadio="AddKondisi"
              typeFilter="kondisi"
              onToggle={() => {
                setSkuData({ value: "", type: "sku", group: "kelipatan" });
                setSelectedSKU([]);
                setSKU(!isSKU);
              }}
              selectedData={selectedSKU}
              setSelectedData={setSelectedSKU}
              // setSelectedData={(val) => {
              //   setSelectedSKU(val);
              //   handleChangeItems(val, skuData);
              // }}
              filterData={skuData}
              setFilterData={(e) => {
                setSkuData(e);
                handleChangeValue(e.value, skuData.type);
              }}
            />
          )}

          {!isManufaktur && !isKategori && !isSKU ? (
            <div className="bg-light p-3 rounded-lg text-center text-dark">
              <span>Silahkan untuk menambahkan filter</span>
            </div>
          ) : (
            ""
          )}
        </div>

        <PopUpKondisi
          kondisi="product"
          isOpen={openModal}
          onToggle={() => setModal(!openModal)}
          onAddFilter={handleAddFilter}
        />
      </div>
    </Fragment>
  );
};

export default FilterByKondisiProduct;
