import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import SelectList from "@components/selectList";
import SearchBar from "@components/searchBar";
import TableCSI from "@components/table";
import DateRangePicker from "rsuite/DateRangePicker";
import TableDropdown from "@components/customDropdown";

import "rsuite/DateRangePicker/styles/index.css";
import { getListPromoNew } from "@action/promoAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import BadgeStatus from "@components/badgeStatus";
import { toast } from "react-toastify";

const TablePromo = () => {
  const [column, setColumn] = useState([]);
  const [dataPromo, setDataPromo] = useState([]);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [typePromo, setTypePromo] = useState("");
  const [search, setSearch] = useState("");

  const { listPromoNew } = useSelector((state) => state.PromoReducer);

  const dispatch = useDispatch();

  const fetchTable = () => {
    const params = {
      page: 1,
      search,
      start_date: startDate,
      end_date: endDate,
      type_promo: typePromo,
      perPage: 10,
    };

    const queryString = generateQueryParams(params);
    dispatch(getListPromoNew(queryString));
  };
  const generateQueryParams = (params) => {
    const queryString = Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== "")
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    return queryString ? `?${queryString}` : "";
  };

  const handleDelete = (id) => {
    console.log("onotrak id", id);
    setIsFetchedData(false);
    // dispatch(deleteRowPoint(id));
    // dispatch(getListPoint(1));
  };

  const onHandleDeactivate = (value) => {
    console.log("onotrak value", value);
    setIsFetchedData(false);
    // const formData = new FormData();
    // formData.append("active", param);
    // dispatch(updateRowPoint(data.id, formData));
    // dispatch(getListPoint(1));
  };

  useEffect(() => {
    fetchTable();
  }, [search, startDate, endDate, typePromo]);

  useEffect(() => {
    setColumn([
      {
        Header: () => null,
        id: "promoNew",
        columns: [
          {
            Header: "Nama",
            accessor: "nama",
            sortAble: true,
            width: 200,
          },
          {
            Header: "Tipe Promo",
            accessor: "tipe_promo",
            sortAble: true,
            width: 100,
          },
          {
            Header: "Periode",
            accessor: "periode",
            sortAble: true,
            width: 300,
          },
          {
            Header: "Status",
            accessor: "status",
            sortAble: true,
            Cell: ({ row }) => <BadgeStatus status={row.original.status} />,
          },
          {
            accessor: "action",
            Cell: ({ row }) => (
              <TableDropdown
                dropdownContent={[
                  {
                    label: "Detail",
                    type: "link",
                    link: `/dashboard/promotion/detail-promotion/${row.original.id_relation}`,
                  },
                  {
                    label: "Edit",
                    type: "link",
                    link: `/dashboard/promotion/edit/${row.original.id_relation}`,
                  },
                  {
                    label: "Delete",
                    type: "delete",
                  },
                ]}
                row={row.original}
                onDeleteAction={(id) => handleDelete(id)}
                onToggleStatus={(value) => onHandleDeactivate(value)}
              />
            ),
            disableSortBy: true,
            sortAble: false,
          },
        ],
      },
    ]);
  }, []);

  useEffect(() => {
    if (listPromoNew.data && !listPromoNew.loading) {
      setInitialSortBy([
        {
          id: "title",
          desc: false,
        },
      ]);

      const resp = listPromoNew.data.data;
      const resDat = resp.data;
      if (resp === undefined || resp === null) return false;

      const mappedList = resDat.map((items) => ({
        id: items.id,
        id_relation: items.id_relation,
        nama: items.title,
        tipe_promo: items.jenis,
        periode: `${moment(items.start_date).format("DD/MM/YYYY")} - ${moment(
          items.end_date
        ).format("DD/MM/YYYY")}`,
        status: items.active,
      }));

      setDataPromo(mappedList);
      setIsFetchedData(true);
    }
  }, [listPromoNew]);

  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-between">
        <h3>Buat Promo</h3>
        <Link to="/dashboard/promotion/add">
          <button className="px-3 py-2 rounded-lg btn-primary">
            New Promotion
          </button>
        </Link>
      </div>
      <div className="d-flex flex-row justify-content-between my-2">
        <div className="d-flex flex-row w-100">
          <div className="mr-2 w-25">
            <SelectList
              placeholder="Tipe Promo"
              onChange={(e) => {
                setTypePromo(e.target.value);
              }}
              values={[
                { name: "Other", val: "other" },
                { name: "Flash Sale", val: "FLASHALE" },
                { name: "New Promo", val: "NEW PROMO" },
                { name: "Bundle", val: "BUNDLE" },
                { name: "Campaign", val: "CAMPAIGN" },
                { name: "Product Free", val: "PRODUCT FREE" },
              ]}
            />
          </div>
          <DateRangePicker
            size="md"
            placeholder="dd-mm-yyyy ~ dd-mm-yyyy"
            block
            onChange={(val) => {
              setStartDate(
                val && val[0] ? moment(val[0]).format("YYYY-MM-DD") : ""
              );
              setEndDate(
                val && val[1] ? moment(val[1]).format("YYYY-MM-DD") : ""
              );
            }}
          />
        </div>
        <div>
          <SearchBar
            noentry
            onSearch={(value) => {
              setSearch(value);
            }}
          />
        </div>
      </div>
      <div>
        {isFetchedData ? (
          <TableCSI
            columns={column}
            data={dataPromo}
            key={dataPromo}
            initialSortBy={initialSortBy}
          />
        ) : null}
      </div>
    </Fragment>
  );
};

export default TablePromo;
